.container {
  display: flex;
  flex: 1;
}
.content {
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: calc(100% - 500px);
  @media screen and (max-width: 1200px) {
    max-width: calc(100% - 374px);
  }
  @media screen and (max-width: 1024px) {
    max-width: calc(100% - 333px);
  }
}
.panel {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  border-bottom: 1px solid #d9d9d9;
}
.title {
  margin: 0;
  font-style: normal;
  font-weight: bold;
  line-height: 30px;
  text-align: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #000000;
}
.price {
  display: flex;
  position: absolute;
  top: 11px;
  right: 11px;
  align-items: center;
  justify-content: center;
  background: url('../../assets/icons/default-ticket.svg');
  background-size: cover;
}
.priceInner {
  font-style: normal;
  font-weight: bold;
  font-size: 26px;
  line-height: 30px;
  text-align: center;
  letter-spacing: 0.07em;
  color: #000000;
}
.productInformation {
  display: flex;
  position: relative;
}
.productCover {
  img {
    display: block;
    object-fit: cover;
  }
}
.productInformation {
  display: flex;
  background-color: #ffffff;
}
.productDescription {
  display: flex;
  flex: 1;
  align-items: center;
  > div {
    display: block;
    display: -webkit-box;
    margin: 0 30px;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 18px;
    line-height: 36px;
    letter-spacing: 0.04em;
    color: #4b4b4b;
  }
}
.productDetails {
  overflow: hidden;
  overflow-y: auto;
  // padding: 0 35px;
  // height: 300px;
  // flex: 1;
  position: relative;
  // background-color: red;
}
.productsList {
  margin: 50px;
}
.scrollButton {
  position: absolute;
  left: 0;
  right: 0;
  height: 109px;
  width: 100%;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 36px;
  text-align: center;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #959595;
  z-index: 10;
  border: none;
  span {
    margin-right: 10px;
  }
}
.scrollButtonUp {
  top: 0;
  background: linear-gradient(0deg, rgba(242, 242, 242, 0) 0%, #f2f2f2 100%);
}
.scrollButtonDown {
  bottom: 0;
  background: linear-gradient(180deg, rgba(242, 242, 242, 0) 0%, #f2f2f2 100%);
}
.addToCartInner {
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: center;
}
.incompleteWaiver {
  padding: 0 30px;
  p {
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 40px;
    letter-spacing: 0.04em;
    color: #595959;
  }
  ul {
    li {
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 40px;
      letter-spacing: 0.04em;
      color: #595959;
    }
  }
}
.packSlotsCounter {
  display: flex;
  position: absolute;
  width: 220px;
  height: 50px;
  color: #ffffff;
  background-color: #df151c;
  align-items: center;
  justify-content: center;
  border-radius: 25px;
  bottom: -20px;
  left: 50%;
  margin-left: -110px;
  z-index: 20;
}
.footerPanel {
  position: fixed;
  bottom: 0;
  z-index: 3;
  width: calc(100% - 500px);
  @media screen and (max-width: 1200px) {
    width: calc(100% - 374px);
  }
  @media screen and (max-width: 1024px) {
    width: calc(100% - 333px);
  }
}
.cancelButton {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 200px;
  height: 60px;
  z-index: 3;
}
.nextButton {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 200px;
  height: 60px;
  z-index: 3;
}

@media (min-width: 768px) {
  .panel {
    height: 60px;
  }
  .title {
    font-size: 20px;
  }
  .price {
    width: 113px;
    height: 41px;
  }
  .priceInner {
    font-size: 16px;
    line-height: 20px;
  }
  .productInformation {
    min-height: 200px;
  }
  .productCover {
    width: 200px;
    height: 200px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .productDescription {
    > div {
      -webkit-line-clamp: 5;
    }
  }
  .productDetailsInner {
    padding-top: 20px;
    padding-bottom: 60px;
  }
}
@media (min-width: 1024px) {
}
@media (min-width: 1200px) {
  .panel {
    height: 110px;
  }
  .title {
    font-size: 26px;
  }
  .price {
    width: 240px;
    height: 88px;
  }
  .priceInner {
    font-size: 26px;
    line-height: 30px;
  }
  .productCover {
    width: 300px;
    img {
      display: block;
      width: 300px;
    }
  }
}
